import { createBrowserRouter } from "react-router-dom";
import Home from "./Pages/Home";
import MainLayout from "./Layouts/MainLayout";
import Login from "./Pages/Login";
import Hakkimizda from "./Pages/Kurumsal/Hakkimizda";
import Faaliyetlerimiz from "./Pages/Faaliyetler";
import Bagislarim from "./Pages/Bagislarim";
import KategoriDetay from "./Pages/KategoriDetay/KategoriDetay";
import HesapNumarasi from "./Pages/HesapNumaralari/HesapNumarasi";
import HesapNumarasiDetay from "./Pages/HesapNumaralari/HesapNumarasiDetay";
import Iletisim from "./Pages/Iletisim";
import DetayBilgi from "./Pages/DetayBilgi";
import KategoriDetay2 from "./Pages/KategoriDetay/KategoriDetay2";
import KategoriDetay3 from "./Pages/KategoriDetay/KategoriDetay3";
import Projelerimiz from "./Pages/Projelerimiz/Projelerimiz";
import BagisYap from "./Pages/BagisYap";
import SifremiUnuttum from "./Pages/SifremiUnuttum/SifremiUnuttum";
import SifreYenileme from "./Pages/SifremiUnuttum/SifreYenileme";
import SSS from "./Pages/Kurumsal/SSS";
import BasinOdasi from "./Pages/Kurumsal/BasinOdasi";
import Yonetim from "./Pages/Kurumsal/Yonetim";
import PrivateLayout from "./Layouts/PrivateLayout";
import Profile from "./Pages/Profile";
import Ayarlar from "./Pages/Profil/Ayarlar";
import Ozet from "./Pages/Profil/Ozet";
import ZekatFormu from "./Pages/ZekatFormu";
import GonulluForm from "./Pages/GonulluForm";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/register",
        element: <Login />,
      },
      {
        path: "/kurumsal/:slug",
        element: <Hakkimizda />,
      },
      {
        path: "/kurumsal/sss",
        element: <SSS />,
      },
      {
        path: "/kurumsal/basin-odasi",
        element: <BasinOdasi />,
      },
      {
        path: "/kurumsal/yonetim-kurulu",
        element: <Yonetim />,
      },
      
      {
        path: "/faaliyetlerimiz/:slug",
        element: <Faaliyetlerimiz />,
      },
      {
        path: "/projelerimiz/:slug",
        element: <Projelerimiz />,
      },
      {
        path: "/bagislarim",
        element: <Bagislarim />,
      },
      {
        path: "/bagis-yap",
        element: <BagisYap />,
      },
      {
        path: "/kategori-detay",
        element: <KategoriDetay />,
      },
      {
        path: "/kategori-detay2",
        element: <KategoriDetay2 />,
      },
      {
        path: "/kategori-detay3",
        element: <KategoriDetay3 />,
      },
      {
        path: "/hesap-numarasi",
        element: <HesapNumarasi />,
      },
      {
        path: "/hesap-numarasi-detay/:id",
        element: <HesapNumarasiDetay />,
      },
      {
        path: "/iletisim",
        element: <Iletisim />,
      },
      {
        path: "/detay-bilgi",
        element: <DetayBilgi />,
      },
      {
        path: "/sifremi-unuttum",
        element: <SifremiUnuttum />,
      },
      {
        path: "/sifre-yenile",
        element: <SifreYenileme />,
      },
      {
        path: "/zekat-formu",
        element: <ZekatFormu />,
      },
      {
        path: "/gonullu-formu",
        element: <GonulluForm />,
      },
    ],
  },
  {
    path:"/",
    element:<PrivateLayout />,
    children:[
      {
        path: "/profil/ozet",
        element: <Ozet />,
      },
      {
        path: "/profil/hesap",
        element: <Ayarlar />,
      }
    ]
  }
]);
export default routes;
