import React, { Fragment, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { api } from "../utils/api";

const MainLayout = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, [pathname]);
  const [projects, setProjects] = useState([]);
  const [faaliyetler, setFaaliyetler] = useState([]);

  const getProjects = () => {
    api()
      .get("site-about/projelerimiz-all")
      .then((res) => {
        setProjects(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getFaaliyetler = () => {
    api()
      .get("site-about/faaliyet")
      .then((res) => {
        setFaaliyetler(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getProjects();
    getFaaliyetler();
  }, []);

  return (
    <Fragment>
      <Header projects={projects} faaliyetler={faaliyetler}/>
      <Outlet />
      <Footer projects={projects} faaliyetler={faaliyetler} />
    </Fragment>
  );
};

export default MainLayout;
